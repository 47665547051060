<template>
  <div class="bg-white rounded-lg p-4 mb-2">
    <div class="flex items-center justify-between mb-2">
      <span class="text-xs">{{
        $t(`extensive-journal.${languageKeyOne}`)
      }}</span>
      <span class="text-xs">{{
        $t(`extensive-journal.${languageKeyTwo}`)
      }}</span>
    </div>
    <vue-slider
      @change="onSliderChange"
      class="flex-1"
      :value="+max > 0 ? 3 : -3"
      step="1"
      tooltip="none"
      :min="min"
      :max="max"
      :process="false"
    />
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  name: "ExtensiveItem",

  components: {
    VueSlider
  },

  props: {
    question: {
      type: String
    },
    languageKeyOne: {
      type: String
    },
    languageKeyTwo: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    }
  },

  methods: {
    onSliderChange(value) {
      this.$emit("onSliderChange", { [this.question]: Math.abs(+value) });
    }
  }
};
</script>

<style scoped>
.slider {
  margin: 0;
}
</style>
