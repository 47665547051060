<template>
  <wrapper>
    <div class="flex items-center justify-between">
      <h1 class="text-2xl font-bold">
        {{ $t(`${translationPrefix}.title`) }}
      </h1>
      <span @click="addNote" class="flex items-center px-4 py-2 text-xs font-bold rounded-full" :class="{
        'bg-white text-brand-dark': isCheckedIn,
        'bg-brand-dark text-white': !isCheckedIn,
      }">
        <icon-plus class="w-3 h-3 mr-2" />
        {{ $t("pages.journal.add") }}
      </span>
    </div>

    <div class="mt-4 mb-4">
      <div class="flex items-center justify-around p-2 bg-white rounded-lg">
        <div>
          <t-input :placeholder="$t('pages.search_placeholder')" class="mb-2 text-black placeholder-black not-rounded"
            variant="journal" type="text" v-model="searchText" />

          <div class="flex gap-2">
            <t-select variant="stats" v-model="period.month" class="text-black" :placeholder="$t('dates.month')"
              :options="monthValues" />
            <t-select variant="stats" v-model="period.year" class="text-black" :placeholder="$t('dates.year')"
              :options="yearValues" />
          </div>
        </div>

        <t-button :disabled="searching || loading" variant="circle"
          class="grid w-12 h-12 rounded-full place-items-center bg-brand-dark">
          <icon-search class="w-6 h-6 text-white fill-current" v-if="!isSearching" @click="searchJournalEntries" />
          <icon-close class="w-6 h-6 text-white fill-current" v-else :class="{ 'text-brand-dark': isCheckedIn }"
            @click="clearFilters" />
        </t-button>
      </div>
    </div>

    <loading v-if="loading" />

    <div v-else-if="!loading && !notes.length">
      <div class="relative flex items-center justify-center my-12">
        <icon-notebook class="relative z-20 w-20 h-20" />
        <blob class="absolute z-10 w-64 h-64 opacity-25" />
      </div>

      <div class="text-sm text-center" v-if="hasSearchQuery">
        {{ $t("journal.empty") }}
      </div>
      <div class="text-sm text-center" v-else>
        {{ $t("journal.empty--journal") }}
      </div>
    </div>

    <div v-else>
      <div v-for="date in dates" :key="date" class="mb-4">
        <h3 class="mb-2 font-bold">
          {{ capitalize($moment(date).format("dddd D MMMM, YYYY")) }}
        </h3>

        <div v-for="note in filteredNotes[date]" :key="note.id" class="relative flex items-center space-y-2">
          <div class="absolute flex items-center space-x-1 left-1">
            <div class="flex items-center justify-center w-16 h-16 mt-2 bg-red-200 rounded-lg">
              <icon-loading v-if="isDeleting" class="w-4 h-4 text-red-400 fill-current animate-spin" />

              <icon-trash v-else @click="deleteJournalItem(note)" class="w-6 h-6 text-red-600 fill-current" />
            </div>
          </div>

          <note-item data-draggable="true" class="relative transition-all checklist__item"
            @touchmove.native="handleSwipe" @touchstart.native="handleSwipeStart"
            @touchend.native="handleSwipeEnd($event, 75)" :note="note" @onPencilClick="onPencilClick" />
        </div>
      </div>

      <observer @onIntersect="onIntersect" />
    </div>

    <add-note-popup v-if="notePopupOpen" :note="selectedNote" @close="handleClose" />
  </wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import IconPlus from "assets/icons/plus.svg";
import IconNotebook from "assets/icons/paper-clock.svg";
import IconSearch from "assets/icons/search.svg";
import IconClose from "assets/icons/close.svg";
import IconTrash from "assets/icons/trash-bin.svg";
import NoteItem from "components/Journal/NoteItem";
import AddNotePopup from "./AddNotePopup";
import Observer from "components/Shared/Observer";
import JournalRepository from "repositories/JournalRepository";
import Swipe from "../../mixins/Swipe";
import Blob from "assets/icons/blob.svg";

export default {
  name: "Index",

  mixins: [Swipe],

  components: {
    Observer,
    NoteItem,
    IconPlus,
    AddNotePopup,
    IconTrash,
    IconSearch,
    IconClose,
    IconNotebook,
    Blob
  },

  async mounted() {
    this.loading = true;
    await this.init();
    this.loading = false;
  },

  watch: {
    period: {
      handler() {
        this.reInitialize();
      },
      deep: true,
    },

    searchText: {
      handler(currentValue) {
        if (!currentValue) {
          this.reInitialize();
        }
      },
    },
  },

  data() {
    return {
      translationPrefix: "journal",
      selectedNote: null,
      notePopupOpen: false,
      dates: null,
      notes: [],
      filteredNotes: [],
      currentPage: 1,
      hasMorePages: false,
      loading: true,
      isDeleting: false,
      searching: false,
      isSearching: false,
      searchText: "",
      period: {
        year: "",
        month: "",
      },
    };
  },

  methods: {
    async searchJournalEntries() {
      this.isSearching = true;
      this.searching = true;
      await this.reInitialize();
      this.searching = false;
    },

    async clearFilters() {
      this.searching = true;
      this.searchText = "";
      this.period.year = "";
      this.period.month = "";
      await this.reInitialize();
      this.isSearching = false;
      this.searching = false;
    },

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    async getNotes() {
      const response = await JournalRepository.getNotes(
        this.currentPage,
        this.period,
        this.searchText
      );
      if (response.success) {
        this.hasMorePages = response.data.has_more_pages;
        this.currentPage++;

        return response.data.data;
      }
    },

    async init() {
      this.notes = await this.getNotes();
      this.dates = this.uniqueDates();
      this.filteredNotes = this.filterNotesPerDate();
    },

    uniqueDates() {
      return [...new Set(this.notes.map((note) => note.created_at_date))];
    },

    filterNotesPerDate() {
      let notesArray = {};

      this.dates.forEach((date) => {
        const filteredDateNotes = this.notes.filter(
          (note) => note.created_at_date === date
        );
        notesArray = { [date]: filteredDateNotes, ...notesArray };
      });

      return notesArray;
    },

    addNote() {
      this.notePopupOpen = true;
    },

    async handleClose() {
      this.reInitialize();
      this.notePopupOpen = false;
      this.selectedNote = null;
    },

    onPencilClick(note) {
      this.selectedNote = note;
      this.notePopupOpen = true;
    },

    async deleteJournalItem(note) {
      if (confirm(this.$t("journal.delete_confirmation"))) {
        this.isDeleting = true;
        await JournalRepository.deleteNote(note.uuid);
        await this.reInitialize();
        this.isDeleting = false;
        window.scroll({ top: 0, behavior: "smooth" });
      }
    },

    async onIntersect() {
      if (this.hasMorePages) {
        const notes = await this.getNotes();
        this.notes = [...this.notes, ...notes];
        this.dates = this.uniqueDates();
        this.filteredNotes = this.filterNotesPerDate();
      }
    },

    async reInitialize() {
      this.currentPage = 1;
      await this.init();
    },
  },

  computed: {
    ...mapGetters({
      isCheckedIn: "isCheckedIn",
    }),

    hasSearchQuery() {
      return this.searchText || this.period.year || this.period.month;
    },

    yearValues() {
      return Array(3)
        .fill()
        .map((_, i) => ({
          label: this.$moment().subtract(i, "years").format("YYYY"),
          value: +this.$moment().subtract(i, "years").format("YYYY"),
        }));
    },

    monthValues() {
      return Array(12)
        .fill()
        .map((_, i) => ({
          label: Object.values(this.$t("dates.months"))[i],
          value: Object.keys(this.$t("dates.months"))[i],
        }));
    },
  },
};
</script>

<style scoped>
.not-rounded {
  border-radius: 0 !important;
}
</style>
