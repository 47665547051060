<script>
export default {
  methods: {
    handleSwipe(e) {
      let element = e.target;
      let clientX = e.changedTouches["0"].clientX;
      let boundingLeft = element.getBoundingClientRect().left;

      if (!element.getAttribute("data-draggable")) {
        boundingLeft = element
          .closest("[data-draggable]")
          .getBoundingClientRect().left;
        element = element.closest("[data-draggable]");
      }

      const x = clientX - boundingLeft;

      if (
        (this.startX - x) / Math.PI < 0 &&
        Math.abs((this.startX - x) / Math.PI) < 200
      ) {
        element.style.left = `${Math.abs((this.startX - x) / Math.PI)}px`;
      }
    },
    handleSwipeStart(e) {
      this.startX =
        e.changedTouches["0"].clientX - e.target.getBoundingClientRect().left;
    },
    handleSwipeEnd(e, leftOffset) {
      let element = e.target;
      if (!element.getAttribute("data-draggable")) {
        element = element.closest("[data-draggable]");
      }
      if (+element.style.left.split("px")[0] > 25) {
        element.style.left = `${leftOffset}px`;
        return;
      }

      element.style.left = 0;
    }
  }
};
</script>
