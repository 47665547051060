<template>
  <div
    class="relative mb-2 flex w-full items-start justify-between rounded-lg bg-white px-4 py-2"
    @click="onNoteClick"
  >
    <div class="flex w-full">
      <div>
        <journal-path class="mt-2 mr-2 h-3 w-3" />
      </div>

      <div class="w-11/12">
        <div class="flex w-full justify-between">
          <div>
            <h4 class="text-lg font-bold text-brand-dark">
              {{ note.created_at_time }}
            </h4>
            <router-link
              :to="{ name: 'my-event', params: { uuid: note.event.uuid } }"
              class="text-xs text-gray-400"
              v-if="!!note.event"
              >Event: {{ note.event.name }}</router-link
            >
          </div>
          <mood-icon :value="note.mood" class="h-12 w-12" />
        </div>

        <div v-if="content" class="w-48 text-brand-dark">
          <p :class="{ truncate: !noteOpen }">{{ content }}</p>
        </div>

        <div
          v-if="!!preptoolAnswers.length && preptoolAnswersOpen"
          class="relative"
        >
          <div class="my-4 h-0.5 w-full rounded-md bg-gray-400"></div>
          <div class="w-5/6">
            <div
              v-for="preptoolAnswer in preptoolAnswers"
              :key="preptoolAnswer.question"
              class="mb-4 space-y-2 text-sm"
            >
              <p class="font-semibold text-black">
                {{ preptoolAnswer.question }}
              </p>
              <p class="text-gray-400">{{ preptoolAnswer.answer }}</p>
            </div>
          </div>
          <icon-lightbulb class="absolute top-4 right-0 h-8 w-8" />
        </div>

        <div
          v-if="!!preptoolAnswers.length"
          class="absolute -top-3 -right-3 grid h-8 w-8 place-items-center rounded-full bg-white shadow-lg"
        >
          <icon-lightbulb class="h-5 w-5 fill-current text-brand-primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JournalPath from "assets/icons/journal_path.svg";
import IconLightbulb from "assets/icons/lightbulb.svg";
import MoodIcon from "../MoodIcon";

export default {
  name: "NoteItem",

  props: {
    note: {
      type: Object,
    },
  },

  components: {
    IconLightbulb,
    MoodIcon,
    JournalPath,
  },

  data() {
    return {
      noteOpen: false,
      preptoolAnswersOpen: false,
    };
  },

  methods: {
    onNoteClick() {
      this.noteOpen = !this.noteOpen;
      this.preptoolAnswersOpen = !this.preptoolAnswersOpen;
    },
    enter(el) {
      el.style.height = "auto";
      const height = getComputedStyle(el).height;
      el.style.height = 0;
      getComputedStyle(el);

      setTimeout(() => {
        el.style.height = height;
      });
    },
    afterEnter(el) {
      el.style.height = "auto";
    },
    leave(el) {
      el.style.height = getComputedStyle(el).height;

      getComputedStyle(el);
      setTimeout(() => {
        el.style.height = 0;
      });
    },
  },

  computed: {
    content() {
      if (this.note.content && !this.note.note) {
        return this.note.content;
      } else if (!this.note.content && this.note.note) {
        return this.note.note;
      }
      return "";
    },

    preptoolAnswers() {
      if (this.note.answers) {
        return JSON.parse(this.note.answers) ?? [];
      }

      return [];
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}
</style>
