<template>
  <portal to="bottom">
    <div v-cloak>
      <transition
        enter-active-class="transition duration-100ms ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition duration-100ms ease-in-out"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <div
          class="fixed inset-0 z-99 max-h-screen overflow-hidden bg-brand-dark-75 backdrop backdrop-blur-5"
          ref="popupBackdrop"
          v-cloak
          @click="close"
        ></div>
      </transition>

      <transition
        enter-active-class="transition transform-gpu duration-300ms ease-in-out"
        enter-class="translate-y-full opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition transform-gpu duration-300ms ease-in-out"
        leave-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-full opacity-0"
        appear
      >
        <div
          class="fixed inset-0 top-5 z-100 h-auto overflow-hidden overscroll-contain rounded-t-lg md:inset-y-24 md:inset-x-24 md:rounded-lg"
          :class="{ 'bg-white': !dark, 'bg-brand-dark text-white': dark }"
          ref="popupWrapper"
          v-cloak
        >
          <div
            class="shadow-xs sticky inset-x-0 top-0 z-110 h-14 select-none bg-opacity-70 leading-loose backdrop-filter backdrop-blur-50 backdrop-opacity-70"
            :class="{ 'bg-white': !dark, 'bg-brand-dark text-white': dark }"
            @touchstart="dragStart"
            @touchmove="dragMove"
            @touchend="dragEnd"
            ref="popupHeading"
          >
            <button
              type="button"
              @click="close"
              class="focus:outline-none absolute top-5 right-5 z-40 h-5 w-5 font-bold"
              key="popup-close"
            >
              <icon-close />
            </button>

            <h5
              class="mb-4 flex h-10 items-center justify-start bg-inherit px-8 pt-4 text-xl font-bold text-inherit"
              :class="{ 'text-white': dark }"
              v-if="!!title"
            >
              {{ title }}
            </h5>
          </div>
          <div
            class="scrollbox relative h-full overflow-y-auto overscroll-contain p-8 pb-32"
            :class="{ 'prose-dark': dark }"
          >
            <slot />
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
import IconClose from "assets/icons/close.svg";

export default {
  components: {
    IconClose,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      y: 0,
      top: 0,
      percentValueOfMousePosition: 0,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },

    dragStart(/* event */) {
      // const rect = event.target.getBoundingClientRect();
      const rect = this.$refs.popupWrapper.getBoundingClientRect();
      this.top = rect.top;
    },

    dragMove(event) {
      const clientY = event.clientY || event.targetTouches[0].clientY;

      if (clientY - this.top < this.y) return;

      this.y = clientY - this.top;
      const parent = this.$refs.popupWrapper;
      this.percentValueOfMousePosition = (
        ((this.y + this.top / 2) / window.innerHeight) *
        100
      ).toFixed(2);

      if (this.percentValueOfMousePosition < 100) {
        parent.style.top = `${this.percentValueOfMousePosition}%`;
      }
    },

    dragEnd(/* event */) {
      const parent = this.$refs.popupWrapper;
      parent.classList.add("transition-all", "ease-in", "duration-400");

      if (this.percentValueOfMousePosition > 5) {
        parent.style.top = "100%";

        setTimeout(() => {
          this.$emit("close");
        }, 500);
      }
    },
  },
};
</script>
