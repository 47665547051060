<template>
  <popup-pane @close="onClose" :dark="isCheckedIn" :title="$t('pages.journal.add_mood')">
    <form @submit.prevent="handleSubmit" class="flex flex-col h-full">
      <div class="flex-1">
        <icon-trash
          v-if="note"
          class=" absolute top-4 right-12"
          @click="deleteNote"
        />

        <p class="font-bold">
          {{ $t("pages.journal.select_mood") }}
        </p>

        <mood-selector @update="onMoodChange" :mood="mood" />

        <t-textarea
          class="h-48"
          :placeholder="$t('pages.journal.textarea')"
          v-model="content"
        />
      </div>

      <t-button type="submit" class="mb-2">{{
        $t("pages.journal.save_mood")
      }}</t-button>
    </form>
  </popup-pane>
</template>

<script>
import { mapGetters } from "vuex";
import PopupPane from "components/PopupPane";
import MoodSelector from "components/MoodSelector";
import IconTrash from "assets/icons/trash-bin.svg";

export default {
  name: "AddNotePopup",
  components: { MoodSelector, PopupPane, IconTrash },
  props: {
    note: {
      type: Object,
    },
  },

  data() {
    return {
      content: this.note?.content || "",
      mood: this.note?.mood || "neutral",
    };
  },

  methods: {
    deleteNote() {
      this.$store.commit("journal/SET_NOTE_TO_DELETE", this.note);
      this.$store.commit("TOGGLE_DELETE_NOTE_MODAL", true);
      this.onClose();
    },

    onMoodChange(mood) {
      this.mood = mood;
    },

    onClose() {
      this.$emit("close");

      this.mood = "neutral";
      this.content = "";
    },

    handleSubmit() {
      if (!this.note) {
        this.$store.dispatch("journal/createNote", {
          content: this.content,
          mood: this.mood,
        });
      } else {
        this.$store.dispatch("journal/updateNote", {
          id: this.note.id,
          content: this.content,
          mood: this.mood,
        });
      }

      this.$emit("close");
    },
  },
  
  computed: {
    ...mapGetters(["isCheckedIn"]),
  },
};
</script>
