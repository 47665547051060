<template>
  <wrapper>
    <h1 class="font-bold text-2xl mb-4">{{ $t("extensive-journal.title") }}</h1>

    <div
      class="flex flex-col items-center justify-center space-y-4 text-center layout-inner"
      v-if="loading"
    >
      <icon-loading class="w-4 h-4 text-brand-blue animate-spin" />
      <p class="text-xs font-semibold">{{ $t("action.loading") }}</p>
    </div>

    <template v-else>
      <form @submit.prevent="handleSubmit" v-if="canSubmitDailyMood">
        <div>
          <h3 class="mb-2">{{ $t("extensive-journal.form-title") }}...</h3>

          <div class="flex flex-col w-full bg-white rounded-lg p-4 mb-4">
            <mood-selector @update="onMoodChange" />
          </div>

          <extensive-item
            v-for="(journalItem, index) in journalItems"
            :key="journalItem.langKey1"
            :question="`question-${index + 1}`"
            :language-key-one="journalItem.langKey1"
            :language-key-two="journalItem.langKey2"
            :min="journalItem.min"
            :max="journalItem.max"
            @onSliderChange="handleSlideChange"
          />
        </div>

        <t-button type="submit" class="mt-4">{{ $t("form.submit") }}</t-button>
      </form>

      <div class="bg-white p-4 rounded-lg" v-else>
        <p>
          {{ $t("extensive-journal.already-filled-in") }}
        </p>

        <t-button class="mt-8" :to="{ name: 'home' }">{{
          $t("back_home")
        }}</t-button>
      </div>
    </template>
  </wrapper>
</template>

<script>
import ExtensiveItem from "components/Journal/ExtensiveItem";
import { extensiveJournalItems } from "utils/constants";
import journalRepository from "repositories/JournalRepository";
import MoodSelector from "components/MoodSelector";
import { mapGetters } from "vuex";

export default {
  name: "Extensive",
  components: { MoodSelector, ExtensiveItem },

  data() {
    return {
      journalItems: extensiveJournalItems,
      canSubmitDailyMood: false,
      loading: false,
      answers: {
        "question-1": "3",
        "question-2": "3",
        "question-3": "3",
        "question-4": "3",
        "question-5": "3",
        "question-6": "3",
      },
      mood: "neutral",
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.loading = true;

      const data = await journalRepository.checkIfIsEligibleToCreateExtensiveMood();
      if (data?.success) {
        this.canSubmitDailyMood = data.data.extensive_journal;
      }

      this.loading = false;
    },

    handleSlideChange(value) {
      this.answers = {
        ...this.answers,
        ...value,
      };
    },

    async handleSubmit() {
      const answersLength = Object.keys(this.answers).length;
      const totalScore = Object.values(this.answers).reduce(
        (prevValue, currValue) => Number(prevValue) + Number(currValue)
      );
      const avgScore = totalScore / answersLength;
      await journalRepository.createDailyMood({
        answers: this.answers,
        avgScore,
        mood: this.mood,
        moodType: "extensive",
      });
      this.$gtm.trackEvent({
        category: "Extensive mood",
        action: "click",
        label: "User filled in extensive mood",
        value: `${this.user.uuid} has submitted data for his extensive mood`,
      });

      await this.$router.push({ name: "home" });
    },

    onMoodChange($event) {
      this.mood = $event;
    },
  },

  computed: {
    ...mapGetters({
      user: "getProfile",
    }),
  },
};
</script>

<style scoped>
.slider {
  margin: 0;
}
</style>
