<template>
  <div class="observer" />
</template>

<script>
export default {
  name: "Observer",
  props: {
    options: {
      type: Object
    }
  },
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    const options = this.options || {};

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("onIntersect");
      }
    }, options);
    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  }
};
</script>

<style scoped></style>
